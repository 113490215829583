<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Navbar/>
    <FirstScreen/>
    <About/>
    <Slider/>
    <Arguments/>
    <Gallery/>
    <Form/>
    <Footer/>
    <Modal/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import FirstScreen from './components/FirstScreen.vue'
import About from './components/About.vue'
import Slider from './components/Slider.vue'
import Arguments from './components/Arguments.vue'
import Gallery from './components/Gallery.vue'
import Form from './components/Form.vue'
import Footer from './components/Footer.vue'
import Modal from './components/Modal.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    FirstScreen,
    About,
    Slider,
    Arguments,
    Gallery,
    Form,
    Footer,
    Modal  
  },
  metaInfo: {
    title: 'Франшиза Клиники «9 месяцев»',
    meta: [
      {
        name: 'description',
        content: 'Стабильный бизнес с оборотом 40 000 000 в год для города миллионника.'
      }
    ]
  }
}
</script>

<style lang="scss">
  #app {
    overflow: hidden;
    max-width: 1920px;
    margin: auto;
    box-shadow: 0 0 40px rgb(162 167 151 / 15%);
  }
</style>
